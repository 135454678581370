export * from './general'
export * from './contentful'
export * from './embeds'
export * from './products'
export * from './viewport'
export * from './ui'

// TODO: Move to /parameters/general
export const ROUTES = {
	HOME: '/',
	ABOUT: '/about',
	LOANS: '/loans',
	INSURANCE: '/insurance',
	PERSONAL_LOANS: '/products/loans',
	NATIVE_LOANS: '/explore/loans',
	NATIVE_STUDENT_LOAN_REFINANCE: '/explore/student-loan-refinance',
	STUDENT_LOAN_REFINANCE: '/products/student-loan-refinance',
	AUTO_REFINANCE: '/products/loans/auto-refinance',
	CREDIT_CARDS: '/explore/credit-cards',
	SAVINGS: '/explore/savings',
	LIFE_INSURANCE:
		'https://offers.evenfinancial.com/channelTrackingOfferRedirect/6438c9a3-1c2b-4481-819c-8ca2414e20e8/7bc97c07-acdd-4596-b4f3-6716a6432427',
	NATIVE_LIFE_INSURANCE:
		'https://offers.evenfinancial.com/channelTrackingOfferRedirect/6438c9a3-1c2b-4481-819c-8ca2414e20e8/7bc97c07-acdd-4596-b4f3-6716a6432427',
	AUTO_INSURANCE: '/explore/auto-insurance/redirect',
	MORTGAGE: '/explore/mortgage',
	MORTGAGE_REFINANCE: '/explore/mortgage?refinance=true',
	CREDIT_SCORE_EXTERNAL:
		'https://offers.evenfinancial.com/partner/ref/516a1f88-3d04-4581-b366-0f5d939ee72d/9a87cee4-8df5-4c6f-952d-82743552a4e4',
	LEARN_HUB: '/learn',
	LEARN_ARTICLES: '/learn/articles',
	LEARN_TOPICS: '/learn/topics',
	SUPPORT: '/support',
	CONTACT: '/contact',
	PRIVACY_POLICY: '/privacy',
	TERMS: '/terms',
	DASHBOARD: '/dashboard',
	SIGNUP: '/account/register',
	SEARCH: '/apply',
	SEARCH_ACTIVE: (searchId: string) => `/apply/${searchId}`,
	SEARCH_RESULTS: (searchId: string) => `/apply/${searchId}/results`,
	SEARCH_HISTORY: '/search/history',
	READING_LIST: '/learn/reading-list',
	ACCOUNT: '/account',
	NEW_SEARCH: '/search/new',
	DISCLOSURE: '/disclosure',
	PARTNERS: '/learn/partners',
	TU_MORTGAGE: '/affiliate/transunion-mortgage',
	TU_MORTGAGE_REFINANCE: '/affiliate/transunion-mortgage-refinance',
	TRUSTPILOT_REVIEWS: '/about/reviews',
	GLBA: 'https://cms.engine.onevenfinancial.com/assets/88dd991b-12f7-495a-a2ac-c0017827112d?download=true',
	DONOTSELL: 'https://privacy-central.securiti.ai/#/dsr/8377b603-9e1e-4139-b33c-9847dcafc801'
}

// Legacy colors, use colors from parameters/ui
export const COLORS = {
	// Green
	MALACHITE_GREEN: '#14e075',
	CARIBEAN_GREEN: '#04c699',
	// Orange
	SAFETY_ORANGE: '#ff7200',
	TENNE_ORANGE: '#d55f00',
	// Purple
	DARK_ORCHID_PURPLE: '#833ec6',
	KSU_PURPLE: '#592b86',
	ICON_BG_PURPLE: '#A58DC0',
	RUSSIAN_VIOLET_PURPLE: '#3b1c5a',
	// Red
	RED: '#f03d3d',
	// Other
	WHITE: '#ffffff',
	SMOKE_WHITE: '#f4f4f4',
	BLACK: '#000000',
	JET_BLACK: '#323232',
	LICORICE: '#191515',
	PLATINUM: '#e2e5e9',
	LIGHT_PURPLE: '#f6f8ff',
	GREEN_FILL: '#e2fff0',
	ICON_ACTIVE: 'rgba(20, 224, 117, 0.4)',
	ICON_INACTIVE: 'rgba(152, 152, 152, 0.4)',
	PURPLE_BACKGROUND: '#A58DC0',
	MAGNOLIA: '#f9f2ff',
	// GRAY
	SPANISH_GRAY: '#989898',
	NEW_GRAY: '#b7c2c9',
	BORDER_GRAY: '#e3e3e3',
	OTHER_GRAY: '#bdc4c9',
	FRESH_GRAY: '#0d0808',
	ALTO: '#dddddd',
	DOVE_GRAY: '#6E6E6E',
	LEGAL_GRAY: '#2f2f2f'
}

// TODO: Remove this and use "devices" from /parameters/viewport
export const PAGE_BREAKPOINTS = {
	DESKTOP_LARGE: 'max-width: 1366px',
	DESKTOP_SMALL: 'max-width: 1024px',
	DESKTOP_MEDIUM: 'max-width: 1200px',
	TABLET_LANDSCAPE: 'max-width: 980px',
	TABLET_PORTRAIT: 'max-width: 768px',
	MOBILE: 'max-width: 560px',
	MOBILE_SMALL: 'max-width: 375px',
	MOBILE_EXTRA_SMALL: 'max-width: 320px'
}

// TODO: Remove this and use "containerWidth" from /parameters/viewport
export const PAGE_WIDTH = '1366px'

// TODO: Move somewhere else
export const MortgageStateWhitelist = [
	'AL',
	'AK',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FL',
	'GA',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NJ',
	'NM',
	'NC',
	'ND',
	'OH',
	'OK',
	'OR',
	'PA',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'WA',
	'WV',
	'WI',
	'WY'
]
